import React, { useState, useCallback, useMemo } from 'react';
import { navigate } from 'gatsby';

import { Gallery, GalleryPhoto } from '../components/gallery';
import Layout from '../components/layout';
import SEO from "../components/seo";
import { VerticalLine } from '../components/verticalLine';
import { PageBlockModel, PageBlockImages } from '../servermodels/pageBlockModels';
import { GalleryStateObj, renderPageBlock } from './renderPageBlock';
import { getEventSystem } from '../events';

type Props = {
    pageContext: {
        product: {
            id: number
            Title: string
            Content: {
                data: {
                    Content: string
                }
            }
            Picture: {
                alternativeText: string
                caption: string
                localFile: {
                    publicURL: string
                }
            }
            SeoUrl: string
            MetaDescription: string
            MetaKeywords: string
            DetailContent: PageBlockModel[]
        }
    }
}

const breadCrumbs = [{
    title: 'Produkty',
    url: '/'
}];

function createGalleryData(pageBlocks: PageBlockModel[]): GalleryPhoto[] {
    if (!pageBlocks) {
        return []
    }

    const result: GalleryPhoto[] = [];

    pageBlocks.forEach(p => {
        if (p.strapi_component !== 'page-blocks.images') {
            return;
        }

        const b = p as PageBlockImages;

        b.Images.forEach(i => {
            result.push({
                original: i.localFile.publicURL,
                thumbnail: i.localFile.publicURL,
                originalTitle: i.caption,
                description: i.caption,
            });
        });
    });

    return result;
}

const Product: React.FC<Props> = (props) => {
    const [showGallery, setShowGallery] = useState(false);
    const [galleryIndex, setGalleryIndex] = useState(0);

    const p = props.pageContext.product;

    const handleHide = useCallback(() => { setShowGallery(false) }, [false]);
    const handleShow = useCallback((imgIndex: number) => {
        setShowGallery(true);
        setGalleryIndex(imgIndex);
    }, [true]);
    
    const galleryData = useMemo(() => createGalleryData(p.DetailContent), [p.DetailContent]);

    const stateObj: GalleryStateObj = {
        imageIndex: 0,
    }

    return (
        <Layout currentPageTitle={p.Title} breadcrumbs={breadCrumbs} contentIsPage>
            <SEO title={p.Title} description={p.MetaDescription} keywords={p.MetaKeywords} />
            <Gallery show={showGallery} photos={galleryData} onClose={handleHide} startIndex={galleryIndex} />
            <VerticalLine topSelector=".product-detail--top" bottomSelector="footer" />
            <div className="container">
                <div className="col">
                    <div className="row">
                        <div className="product-detail--block product-detail--top">
                            <div className="product-detail--img">
                                <img src={p.Picture.localFile?.publicURL} title={p.Title} alt={p.Picture.alternativeText} />
                            </div>
                            <div className="product-detail--text">
                                <h1>{p.Title}</h1>
                                <div className="product-detail--top-action">
                                    {p.SeoUrl === 'letaky' &&
                                        <div className="product-detail--top-action--calculation">
                                            <button className="product-detail--gallery btn btn--secondary" onClick={() => navigate('/letaky-kalkulace')}>Kalkulace</button>
                                        </div>}
                                    <div>
                                        <button className="product-detail--contact btn btn--primary" onClick={() => getEventSystem().emit('contactClicked')}>Kontaktujte nás</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="product-detail--sep"></div>
                    <div className="row">
                        <div className="product-detail--block">
                            <div className="product-detail--content">
                                {p.DetailContent ?
                                    p.DetailContent.map((block, i) => (
                                        <React.Fragment key={i}>
                                            {renderPageBlock(block, undefined, stateObj, handleShow)}
                                        </React.Fragment>
                                    ))
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Product;